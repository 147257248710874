import { find, findAll } from "../utils/dom";
import Swiper from "swiper";


const pageInstituto = () => {
	const page = find(`[js-page='instituto']`);
	if (!page) return;


	function mudarNumeroPageAtivo(nPageActive) {
		const pageActive = findAll("[js-page-active]");

		pageActive?.forEach((page) => {
			if (nPageActive < 10) {
				page.innerText = `0${nPageActive}`;
			} else {
				page.innerText = nPageActive;
			}

		})
	}

	function loadTotalPage(total) {
		const pageTotal = findAll("[js-page-total]");
		const nPageTotal = total.length;
		if (nPageTotal === 0) return;

		const match = matchMedia(`(min-width: 767px)`);

		if (nPageTotal <= 1) {
			find('[js-colaboradores-nav]').style.display = 'none';
			find('[js-colaboradores-nav-mobile]').style.display = 'none';
		}

		pageTotal?.forEach((page) => {
			if (nPageTotal < 10) {
				page.innerText = `0${nPageTotal}`;
			} else {
				page.innerText = nPageTotal;
			}
		})
	}



	function initSwiper(classe) {



		const swiperColaboradores = new Swiper(classe, {
			slidesPerView: "auto",
			spaceBetween: 32,
			watchSlidesProgress: true,
			watchSlidesVisibility: true,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
		});

		const swiperColaboradoresInfo = new Swiper('.swiper-colaboradores-info', {
			effect: "fade",
			thumbs: {
				swiper: swiperColaboradores,
			},
		})


		swiperColaboradores.on("slideChange", () => {
			swiperColaboradoresInfo.slideTo(swiperColaboradores.activeIndex);
		})

		const Bullets = classe.querySelectorAll(".swiper-pagination-bullet");
		Bullets?.forEach((singleBullet) => {



			const observer = new MutationObserver((mutations) => {
				mutations.forEach((mutation) => {
					if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
						if (singleBullet.classList.contains('swiper-pagination-bullet-active')) {
							let arialLabel = singleBullet.getAttribute('aria-label');
							arialLabel = arialLabel.replace('Go to slide ', '');
							mudarNumeroPageAtivo(arialLabel);
						}
					}
				})
			});

			observer.observe(singleBullet, { attributes: true });
		})

		/*swiperColaboradores.on("slideChange", () => {
			const bullets = classe.querySelector('.swiper-pagination-bullet-active');
			let arialLabel = bullets.getAttribute('aria-label');
			arialLabel = arialLabel.replace('Go to slide ', '');
			console.log(arialLabel);
	
			mudarNumeroPageAtivo(arialLabel);
		});*/

		loadTotalPage(classe.querySelectorAll(".swiper-pagination-bullet"));


	}


	const classSwiperColaboradores = findAll('.swiper-colaboradores');
	classSwiperColaboradores?.forEach((singleClass) => {
		initSwiper(singleClass);
	});

	/*
	
	loadTotalPage(swiperColaboradores[0]);
	loadTotalPage(swiperColaboradores[1]);
	
	swiperColaboradores[0].on("slideChange", () => {
		
	
		//mudarNumeroPageAtivo(swiperColaboradores[0].activeIndex + 1);
	});
	swiperColaboradores[1].on("slideChange", () => {
		//mudarNumeroPageAtivo(swiperColaboradores[1].activeIndex + 1);
	});
	
	*/

}

export default pageInstituto;