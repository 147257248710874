import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from "./pages/Login";
import PageInstituto from "./pages/Instituto";

import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import swiperFundadora from "./animation/animationSwiperInstituto";
import swiperTregua from "./animation/animationSwiperTregua";
import mottaEquipeText from "./modules/converteditorjs";
//import instituto from "./modules/instituto";
import cardsessao from "./modules/cardSessao01"
import Swiper from "swiper"
import MenuFunc from "./bazzar/modalMenu";
// import SvgUse from "./utils/SvgUse";

import swiperConnect from "./animation/swiperconecte";
import paginaContato from './modules/paginaContato';
import contatoForm from './modules/contato-conectese';
import contatoFormNewsletter from './modules/newsletter';
import fetchModal from './pages/Tregra';
import scrollToNextSection from './modules/scrollToNextSection';
import animacaoGsapManual from './animation/animacaoGsapManual';
import { find, findAll } from "utils/dom";
import traducao from './modules/traducao';
import video from './modules/video';

import EditorJS from './modules/editorJS';

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()
swiperConnect()



// Pages
swiperFundadora()
swiperTregua()
PageHome()
PageLogin()?.init()
PageInstituto()
//instituto()
cardsessao()

EditorJS()


Fancybox.bind('[data-fancybox="video-banner"]', {
	// Your custom options for a specific gallery
});
function SwiperHome() {
	const swipercont1 = new Swiper(".swiper-container-2", {

		slidesPerView: 1.1,
		speed: 1000,
		autoHeight: true,


		// centerInsufficientSlides:true,
		// centeredSlidesBounds:true,
		slideToClickedSlide: true,
		spaceBetween: 16,

		// speed: 1260, 
		// effect: 'slide', 
		// autoplay: {
		// 	delay: 200,
		// 	disableOnInteraction: true,
		// },




		navigation: {
			nextEl: '.swiper-container-2 #scrollRightBtn',
			prevEl: '.swiper-container-2 #scrollLeftBtn',
		},
		breakpoints: {
			540: {},
			1024: {
				slidesPerView: 2.2,
				centeredSlides: true,
				initialSlide: 1,
			}
		},

	});




	const swiperContainer = document.querySelector('.swiper-container-2');
	// swiperContainer.classList.add('hide-arrows');

	Fancybox.bind('[data-fancybox="carrosel-home"]', {
		// Your custom options for a specific gallery
	});
}



SwiperHome()



function SwiperProdutos() {
	const swiper_produto = new Swiper(".swiper_produto", {

		slidesPerView: 1.1,
		speed: 1000,
		autoHeight: true,

		slideToClickedSlide: true,
		spaceBetween: 16,

		navigation: {
			nextEl: '.swiper_produto #scrollRightBtn',
			prevEl: '.swiper_produto #scrollLeftBtn',
		},
		breakpoints: {
			540: {},
			1024: {
				slidesPerView: 2.2,
				centeredSlides: true,
				initialSlide: 1,
			}
		},



	});



	const swiperContainer = document.querySelector('.swiper_produto');
	// swiperContainer.classList.add('hide-arrows');

	Fancybox.bind('[data-fancybox="carrosel-produto"]', {
		// Your custom options for a specific gallery
	});
}



SwiperProdutos()


function swiperContato() {
	const swipercont1 = new Swiper(".swiper-na-midia", {
		slidesPerView: 1.1,
		spaceBetween: 32,
		breakpoints: {
			540: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
				slidesPerGroupAuto: true,
			}
		},
		navigation: {
			nextEl: '.swiper-na-midia #scrollRightBtn',
			prevEl: '.swiper-na-midia #scrollLeftBtn',
		},
	})

}
swiperContato()

contatoForm()
contatoFormNewsletter()
paginaContato()



MenuFunc()
traducao()

// utils logs

//video();
logs()


//MenuFunc()



const seta = document.querySelector('.arrow-down-headers')

if (seta) {
	seta.addEventListener('click', (event) => {
		event.preventDefault();
		const href = seta.getAttribute('href');
		const section = document.querySelector(href);
		console.log(section, 'aqui')
		if (section) {
			console.log(section, 'aqui')
			section.scrollIntoView({ behavior: 'smooth' });
		}
	})
}
window.addEventListener('scroll', function () {
	var header = document.getElementById('menu_top');
	var scrollPosition = window.scrollY;

	if (scrollPosition > 20) {
		header.classList.add('scrolou');
	} else {
		header.classList.remove('scrolou');
	}
});

const clasMobile = document.querySelector(".clasMobile")
const clasMobile2 = document.querySelector(".clasMobile2")

if (document.documentElement.clientWidth < 601) {
	functionClasMobile(clasMobile, clasMobile2)
}

function functionClasMobile(add, nadd) {


	add.classList.remove('hidden')

	nadd.classList.remove('hidden')
}



// const swiperCarrossel2 = new Swiper(".swiperCarrossel2", {
// 	slidesPerView: 1, 

// 	slideToClickedSlide:true,
// 	autoHeight: true,


// 	pagination: {
// 		el: ".swiper-pagination",
// 		clickable: true,
// 		renderBullet: function (index, className) {
// 			return  '<span class="' + className + '">' + (index + 1) + "</span>";
// 		},
// 	},



// 		centeredSlides: true,
// 		spaceBetween: 30,



















// });

const swiperTags = new Swiper(".swiper-tags", {
    slidesPerView: 'auto', 
    spaceBetween: 16, 

    navigation: {
        nextEl: '#scrollRightBtn_tags', 
        prevEl: '#scrollLeftBtn_tags', 
    },

    breakpoints: {
        410: {
            slidesPerView: 3, 
        },
        760: {
            slidesPerView: 5, 
        },
      
    },
});



document.addEventListener("DOMContentLoaded", function() {
    const swiperSelos = new Swiper(".swiper-selos", {
        slidesPerView: "auto",
        spaceBetween: 16,
        navigation: {
            nextEl: "#scrollRightBtn_tags_selos",
            prevEl: "#scrollLeftBtn_tags_selos",
        },
        breakpoints: {
            410: {
                slidesPerView: 3,
            },
            760: {
                slidesPerView: 5,
            },
            1024: {
                slidesPerView: "auto",
            },
        },
    });
});






// const menuLinks = document.querySelectorAll('.menu-link');

// //console.log("AQUI",menuLinks[0].href)

// function getCurrentPageUrl() {
// 	return  window.location.href.split('?page')[0];
// }

// const currentUrl = getCurrentPageUrl();

// console.log("URL atual:", currentUrl);

// console.log(menuLinks.length)


// menuLinks.forEach((link) => {
// 	console.log("eu estou aqui", link.href)
// 	if (link.href == currentUrl) {
// 			console.log(link.href);
// 			link.classList.add('active_menu_pesquise');
// 	}
// });


// let linkPagination = document.querySelectorAll('.linkPagination')

// console.log("TESTE",linkPagination)



// linkPagination.addEventListener('click', (event) =>{
// 	event.preventDefault()

// 	function getCurrentPageUrl() {
// 		return  window.location.href.split('?categoria')[1];
// 	}

// 	let valor = getCurrentPageUrl()

// 	console.log("fantasma aqui",valor)


// })



const linkPagination = document.querySelectorAll('.page-list a');
const urlparams = new URLSearchParams(window.location.search)





if (urlparams) {

	const categoria = urlparams.getAll("categoria")
	if (categoria.length > 0) {
		linkPagination.forEach((link) => {
			let newUrl = link.getAttribute("href").replace("?", "&")


			newUrl += `&categoria=${categoria.join("&categoria=")}`

			link.href = `?${newUrl}`






		});

	}


}






// linkPagination.addEventListener('click', (event) => {
// 		console.log("EU LEO",linkPagination)
// });







// const categoria_post = document.querySelector(".categoria_post")
// const hidmap = document.querySelector(".hidmap")

// console.log(categoria_post.innerHTML)

// console.log(hidmap)


// if(categoria_post.innerHTML == "Restaurante") {
// 	hidmap.classList.remove('hidden')
// }



// function modalCases_fundament(){

//     const bodyElement = document.querySelector('body');
//     const htmlElement = document.documentElement;
//     const closeBtn_mobal = document.querySelector("#close_fundamento_home"); 
//     const modal = document.getElementById("myModal_fundamento_carrosel");

//     if(closeBtn_mobal) {


//             closeBtn_mobal.addEventListener('click', () => {

//                 document.body.classList.remove('no-scroll');

//                  modal.style.display = "none";
//                  bodyElement.style.overflow = 'auto';
//                  htmlElement.style.overflow = 'auto';
//                  });     

//     }




// }

function modalCases_fundament() {
	const bodyElement = document.querySelector('body');
	const htmlElement = document.documentElement;
	const closeBtn_mobal = document.querySelector("#close_fundamento_home");
	const modal = document.getElementById("myModal_fundamento_carrosel");



	if (closeBtn_mobal) {
		closeBtn_mobal.addEventListener('click', () => {
			document.body.classList.remove('no-scroll');
			modal.style.display = "none";
			bodyElement.style.overflow = 'auto';
			htmlElement.style.overflow = 'auto';

			// Interromper o vídeo ao fechar o modal
			const iframe = modal.querySelector('iframe');
			if (iframe) {
				iframe.src = '';
			}
		});
	}
}






const destinos_fundamento_home = findAll('.js-destino-carrosel');
let currentIframeIndex = 0;
let iframe = [];
let links = [];

destinos_fundamento_home.forEach((item, index) => {
	item.addEventListener('click', () => {

		console.log("clicou aqui")

		iframe = Array.from(destinos_fundamento_home).map(item => item.querySelector('iframe') ? item.querySelector('iframe').outerHTML : null);
		links = Array.from(destinos_fundamento_home).map(item => item.querySelector('a') ? item.querySelector('a').href : null);
		currentIframeIndex = index;

		if (iframe[currentIframeIndex]) {

			const modal_conteudo = document.getElementById("myModal_fundamento_carrosel");
			const bodyElement = document.querySelector('body');
			const htmlElement = document.documentElement;

			bodyElement.classList.add('no-scroll');


			modal_conteudo.style.display = "block";
			modal_conteudo.scrollTop = 0;

			const conteudoModal = `
                    <div class="relative inset-0 overflow-hidden flex h-screen items-center justify-center">
                        <div class="min-w-[1000px] w-full max-h-[300px] overflow-y-hidden">
                            <div class="relative">
                                <button id="close_fundamento_home" class="absolute right-0 top-1/4 text-white transform -translate-y-1/4 z-10">
                                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="white" stroke-opacity="0.2"/>
                                        <path d="M21.75 21.75L34.25 34.25" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M21.75 34.25L34.25 21.75" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="px-56 md:px-0 w-screen max-h-[800px] overflow-hidden h-screen flex items-center  py-24 z-0 ${iframe.length === 1 ? 'justify-center' : 'justify-between'}" >
                                 ${iframe.length > 1 ? `
                                <button id="prevImage" class="text-white transform bg-gray-200 p-2 z-10">
                                    <svg class="rotate-180" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                                    </svg>
                                </button>
                                ` : ''}
                                <div id="modalImageContainer" class="flex flex-col items-center">
                                    ${iframe[currentIframeIndex]}
                                </div>
                                ${iframe.length > 1 ? `
                                <button id="nextImage" class="text-white transform bg-gray-200 p-2 z-10">
                                    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                                    </svg>
                                </button>
                                ` : ''}
                            </div>
                        </div>
                    </div>
                `;

			const modal = document.getElementById("myModal_fundamento_carrosel");
			modal.querySelector(".modal-content_carrosel").innerHTML = conteudoModal;

			modalCases_fundament();

			document.getElementById('prevImage').addEventListener('click', () => {
				currentIframeIndex = (currentIframeIndex - 1 + iframe.length) % iframe.length;
				document.getElementById('modalImageContainer').innerHTML = iframe[currentIframeIndex];
			});

			document.getElementById('nextImage').addEventListener('click', () => {
				currentIframeIndex = (currentIframeIndex + 1) % iframe.length;
				document.getElementById('modalImageContainer').innerHTML = iframe[currentIframeIndex];
			});

			// Adicionando eventos de teclado
			document.addEventListener('keydown', (event) => {
				if (event.key === 'ArrowLeft') {
					currentIframeIndex = (currentIframeIndex - 1 + iframe.length) % iframe.length;
					document.getElementById('modalImageContainer').innerHTML = iframe[currentIframeIndex];
				} else if (event.key === 'ArrowRight') {
					currentIframeIndex = (currentIframeIndex + 1) % iframe.length;
					document.getElementById('modalImageContainer').innerHTML = iframe[currentIframeIndex];
				}
			});

		} else if (links[currentIframeIndex]) {

			window.open(links[currentIframeIndex], '_blank');
		}
	});
});





const destinos_fundamento_home_ing = findAll('.js-destino-carrosel-ing');
let currentIframeIndex_ing = 0;
let iframe_ing = [];
let links_ing = [];

destinos_fundamento_home_ing.forEach((item, index) => {




	item.addEventListener('click', () => {

		console.log("clicou aqui")

		iframe_ing = Array.from(destinos_fundamento_home_ing).map(item => item.querySelector('iframe') ? item.querySelector('iframe').outerHTML : null);
		links_ing = Array.from(destinos_fundamento_home_ing).map(item => item.querySelector('a') ? item.querySelector('a').href : null);
		currentIframeIndex_ing = index;

		if (iframe_ing[currentIframeIndex_ing]) {

			const modal_conteudo = document.getElementById("myModal_fundamento_carrosel");
			const bodyElement = document.querySelector('body');
			const htmlElement = document.documentElement;

			bodyElement.classList.add('no-scroll');


			modal_conteudo.style.display = "block";
			modal_conteudo.scrollTop = 0;

			const conteudoModal = `
                    <div class="relative inset-0 overflow-hidden flex h-screen items-center justify-center">
                        <div class="min-w-[1000px] w-full max-h-[300px] overflow-y-hidden">
                            <div class="relative">
                                <button id="close_fundamento_home" class="absolute right-0 top-1/4 text-white transform -translate-y-1/4 z-10">
                                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="white" stroke-opacity="0.2"/>
                                        <path d="M21.75 21.75L34.25 34.25" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M21.75 34.25L34.25 21.75" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="px-56 md:px-0 w-screen max-h-[800px] overflow-hidden h-screen flex items-center  py-24 z-0 ${iframe.length === 1 ? 'justify-center' : 'justify-between'}" >
                                 ${iframe_ing.length > 1 ? `
                                <button id="prevImage" class="text-white transform bg-gray-200 p-2 z-10">
                                    <svg class="rotate-180" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                                    </svg>
                                </button>
                                ` : ''}
                                <div id="modalImageContainer" class="flex flex-col items-center">
                                    ${iframe_ing[currentIframeIndex_ing]}
                                </div>
                                ${iframe_ing.length > 1 ? `
                                <button id="nextImage" class="text-white transform bg-gray-200 p-2 z-10">
                                    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                                    </svg>
                                </button>
                                ` : ''}
                            </div>
                        </div>
                    </div>
                `;

			const modal = document.getElementById("myModal_fundamento_carrosel");
			modal.querySelector(".modal-content_carrosel").innerHTML = conteudoModal;

			modalCases_fundament();

			document.getElementById('prevImage').addEventListener('click', () => {
				currentIframeIndex_ing = (currentIframeIndex_ing - 1 + iframe_ing.length) % iframe_ing.length;
				document.getElementById('modalImageContainer').innerHTML = iframe_ing[currentIframeIndex_ing];
			});

			document.getElementById('nextImage').addEventListener('click', () => {
				currentIframeIndex_ing = (currentIframeIndex_ing + 1) % iframe_ing.length;
				document.getElementById('modalImageContainer').innerHTML = iframe_ing[currentIframeIndex_ing];
			});

			// Adicionando eventos de teclado
			document.addEventListener('keydown', (event) => {
				if (event.key === 'ArrowLeft') {
					currentIframeIndex_ing = (currentIframeIndex_ing - 1 + iframe_ing.length) % iframe_ing.length;
					document.getElementById('modalImageContainer').innerHTML = iframe_ing[currentIframeIndex_ing];
				} else if (event.key === 'ArrowRight') {
					currentIframeIndex_ing = (currentIframeIndex_ing + 1) % iframe_ing.length;
					document.getElementById('modalImageContainer').innerHTML = iframe_ing[currentIframeIndex_ing];
				}
			});

		} else if (links_ing[currentIframeIndex_ing]) {





			//console.log(links_ing)
			window.open(links_ing[currentIframeIndex_ing], '_blank');
		}
	});
});



const destinos_fundamento_home_produtos = findAll('.js-destino-carrosel-produtos');
let currentIndex_produtos = 0;
let iframe_produtos = [];
let links_produtos = [];

destinos_fundamento_home_produtos.forEach((item, index) => {
	item.addEventListener('click', () => {

		console.log("clicou aqui")

		iframe_produtos = Array.from(destinos_fundamento_home_produtos).map(item => item.querySelector('iframe') ? item.querySelector('iframe').outerHTML : null);
		links_produtos = Array.from(destinos_fundamento_home_produtos).map(item => item.querySelector('a') ? item.querySelector('a').href : null);
		currentIndex_produtos = index;

		if (iframe_produtos[currentIndex_produtos]) {

			const modal_conteudo = document.getElementById("myModal_fundamento_carrosel");
			const bodyElement = document.querySelector('body');
			const htmlElement = document.documentElement;

			bodyElement.classList.add('no-scroll');


			modal_conteudo.style.display = "block";
			modal_conteudo.scrollTop = 0;

			const conteudoModal = `
                    <div class="relative inset-0 overflow-hidden flex h-screen items-center justify-center">
                        <div class="min-w-[1000px] w-full max-h-[300px] overflow-y-hidden">
                            <div class="relative">
                                <button id="close_fundamento_home" class="absolute right-0 top-1/4 text-white transform -translate-y-1/4 z-10">
                                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="white" stroke-opacity="0.2"/>
                                        <path d="M21.75 21.75L34.25 34.25" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M21.75 34.25L34.25 21.75" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="px-56 md:px-0 w-screen max-h-[800px] overflow-hidden h-screen flex items-center  py-24 z-0 ${iframe_produtos.length === 1 ? 'justify-center' : 'justify-between'}" >
                                 ${iframe_produtos.length > 1 ? `
                                <button id="prevImage" class="text-white transform bg-gray-200 p-2 z-10">
                                    <svg class="rotate-180" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                                    </svg>
                                </button>
                                ` : ''}
                                <div id="modalImageContainer" class="flex flex-col items-center">
                                    ${iframe_produtos[currentIndex_produtos]}
                                </div>
                                ${iframe_produtos.length > 1 ? `
                                <button id="nextImage" class="text-white transform bg-gray-200 p-2 z-10">
                                    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                                    </svg>
                                </button>
                                ` : ''}
                            </div>
                        </div>
                    </div>
                `;

			const modal = document.getElementById("myModal_fundamento_carrosel");
			modal.querySelector(".modal-content_carrosel").innerHTML = conteudoModal;

			modalCases_fundament();

			document.getElementById('prevImage').addEventListener('click', () => {
				currentIndex_produtos = (currentIndex_produtos - 1 + iframe_produtos.length) % iframe_produtos.length;
				document.getElementById('modalImageContainer').innerHTML = iframe_produtos[currentIndex_produtos];
			});

			document.getElementById('nextImage').addEventListener('click', () => {
				currentIndex_produtos = (currentIndex_produtos + 1) % iframe_produtos.length;
				document.getElementById('modalImageContainer').innerHTML = iframe_produtos[currentIndex_produtos];
			});

			// Adicionando eventos de teclado
			document.addEventListener('keydown', (event) => {
				if (event.key === 'ArrowLeft') {
					currentIndex_produtos = (currentIndex_produtos - 1 + iframe_produtos.length) % iframe_produtos.length;
					document.getElementById('modalImageContainer').innerHTML = iframe_produtos[currentIndex_produtos];
				} else if (event.key === 'ArrowRight') {
					currentIndex_produtos = (currentIndex_produtos + 1) % iframe_produtos.length;
					document.getElementById('modalImageContainer').innerHTML = iframe_produtos[currentIndex_produtos];
				}
			});

		} else if (links_produtos[currentIndex_produtos]) {

			window.location.href = links_produtos[currentIndex_produtos];
		}
	});
});


const destinos_fundamento_home_produtos_ing = findAll('.js-destino-carrosel-produtos-ing');
let currentIndex_produtos_ing = 0;
let iframe_produtos_ing = [];
let links_produtos_ing = [];

destinos_fundamento_home_produtos_ing.forEach((item, index) => {
	item.addEventListener('click', () => {

		console.log("clicou aqui")

		iframe_produtos_ing = Array.from(destinos_fundamento_home_produtos_ing).map(item => item.querySelector('iframe') ? item.querySelector('iframe').outerHTML : null);
		links_produtos_ing = Array.from(destinos_fundamento_home_produtos_ing).map(item => item.querySelector('a') ? item.querySelector('a').href : null);
		currentIndex_produtos_ing = index;

		if (iframe_produtos_ing[currentIndex_produtos_ing]) {

			const modal_conteudo = document.getElementById("myModal_fundamento_carrosel");
			const bodyElement = document.querySelector('body');
			const htmlElement = document.documentElement;

			bodyElement.classList.add('no-scroll');


			modal_conteudo.style.display = "block";
			modal_conteudo.scrollTop = 0;

			const conteudoModal = `
                    <div class="relative inset-0 overflow-hidden flex h-screen items-center justify-center">
                        <div class="min-w-[1000px] w-full max-h-[300px] overflow-y-hidden">
                            <div class="relative">
                                <button id="close_fundamento_home" class="absolute right-0 top-1/4 text-white transform -translate-y-1/4 z-10">
                                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="white" stroke-opacity="0.2"/>
                                        <path d="M21.75 21.75L34.25 34.25" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M21.75 34.25L34.25 21.75" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="px-56 md:px-0 w-screen max-h-[800px] overflow-hidden h-screen flex items-center  py-24 z-0 ${iframe_produtos_ing.length === 1 ? 'justify-center' : 'justify-between'}" >
                                 ${iframe_produtos_ing.length > 1 ? `
                                <button id="prevImage" class="text-white transform bg-gray-200 p-2 z-10">
                                    <svg class="rotate-180" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                                    </svg>
                                </button>
                                ` : ''}
                                <div id="modalImageContainer" class="flex flex-col items-center">
                                    ${iframe_produtos_ing[currentIndex_produtos_ing]}
                                </div>
                                ${iframe_produtos_ing.length > 1 ? `
                                <button id="nextImage" class="text-white transform bg-gray-200 p-2 z-10">
                                    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                                    </svg>
                                </button>
                                ` : ''}
                            </div>
                        </div>
                    </div>
                `;

			const modal = document.getElementById("myModal_fundamento_carrosel");
			modal.querySelector(".modal-content_carrosel").innerHTML = conteudoModal;

			modalCases_fundament();

			document.getElementById('prevImage').addEventListener('click', () => {
				currentIndex_produtos_ing = (currentIndex_produtos_ing - 1 + iframe_produtos_ing.length) % iframe_produtos_ing.length;
				document.getElementById('modalImageContainer').innerHTML = iframe_produtos_ing[currentIndex_produtos_ing];
			});

			document.getElementById('nextImage').addEventListener('click', () => {
				currentIndex_produtos_ing = (currentIndex_produtos_ing + 1) % iframe_produtos_ing.length;
				document.getElementById('modalImageContainer').innerHTML = iframe_produtos_ing[currentIndex_produtos_ing];
			});

			// Adicionando eventos de teclado
			document.addEventListener('keydown', (event) => {
				if (event.key === 'ArrowLeft') {
					currentIndex_produtos_ing = (currentIndex_produtos_ing - 1 + iframe_produtos_ing.length) % iframe_produtos_ing.length;
					document.getElementById('modalImageContainer').innerHTML = iframe_produtos_ing[currentIndex_produtos_ing];
				} else if (event.key === 'ArrowRight') {
					currentIndex_produtos_ing = (currentIndex_produtos_ing + 1) % iframe_produtos_ing.length;
					document.getElementById('modalImageContainer').innerHTML = iframe_produtos_ing[currentIndex_produtos_ing];
				}
			});

		} else if (links_produtos_ing[currentIndex_produtos_ing]) {

			window.location.href = links_produtos_ing[currentIndex_produtos_ing];
		}
	});
});






const destinos_fundamento_tregua = findAll('.js-destino-carrosel-tregua');
let currentIndex_tregua = 0;
let iframe_tregua = [];
let links_tregua = [];

destinos_fundamento_tregua.forEach((item, index) => {
	item.addEventListener('click', () => {

		console.log("clicou aqui")

		iframe_tregua = Array.from(destinos_fundamento_tregua).map(item => item.querySelector('iframe') ? item.querySelector('iframe').outerHTML : null);
		links_tregua = Array.from(destinos_fundamento_tregua).map(item => item.querySelector('a') ? item.querySelector('a').href : null);
		currentIndex_tregua = index;

		if (iframe_tregua[currentIndex_tregua]) {

			const modal_conteudo = document.getElementById("myModal_fundamento_carrosel");
			const bodyElement = document.querySelector('body');
			const htmlElement = document.documentElement;

			bodyElement.classList.add('no-scroll');


			modal_conteudo.style.display = "block";
			modal_conteudo.scrollTop = 0;

			const conteudoModal = `
                    <div class="relative inset-0 overflow-hidden flex h-screen items-center justify-center">
                        <div class="min-w-[1000px] w-full max-h-[300px] overflow-y-hidden">
                            <div class="relative">
                                <button id="close_fundamento_home" class="absolute right-0 top-1/4 text-white transform -translate-y-1/4 z-10">
                                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="white" stroke-opacity="0.2"/>
                                        <path d="M21.75 21.75L34.25 34.25" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M21.75 34.25L34.25 21.75" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="px-56 md:px-0 w-screen max-h-[800px] overflow-hidden h-screen flex items-center  py-24 z-0 ${iframe_tregua.length === 1 ? 'justify-center' : 'justify-between'}" >
                                 ${iframe_tregua.length > 1 ? `
                                <button id="prevImage" class="text-white transform bg-gray-200 p-2 z-10">
                                    <svg class="rotate-180" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                                    </svg>
                                </button>
                                ` : ''}
                                <div id="modalImageContainer" class="flex flex-col items-center">
                                    ${iframe_tregua[currentIndex_tregua]}
                                </div>
                                ${iframe_tregua.length > 1 ? `
                                <button id="nextImage" class="text-white transform bg-gray-200 p-2 z-10">
                                    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                                    </svg>
                                </button>
                                ` : ''}
                            </div>
                        </div>
                    </div>
                `;

			const modal = document.getElementById("myModal_fundamento_carrosel");
			modal.querySelector(".modal-content_carrosel").innerHTML = conteudoModal;

			modalCases_fundament();

			document.getElementById('prevImage').addEventListener('click', () => {
				currentIndex_tregua = (currentIndex_tregua - 1 + iframe_tregua.length) % iframe_tregua.length;
				document.getElementById('modalImageContainer').innerHTML = iframe_tregua[currentIndex_tregua];
			});

			document.getElementById('nextImage').addEventListener('click', () => {
				currentIndex_tregua = (currentIndex_tregua + 1) % iframe_tregua.length;
				document.getElementById('modalImageContainer').innerHTML = iframe_tregua[currentIndex_tregua];
			});

			// Adicionando eventos de teclado
			document.addEventListener('keydown', (event) => {
				if (event.key === 'ArrowLeft') {
					currentIndex_tregua = (currentIndex_tregua - 1 + iframe_tregua.length) % iframe_tregua.length;
					document.getElementById('modalImageContainer').innerHTML = iframe_tregua[currentIndex_tregua];
				} else if (event.key === 'ArrowRight') {
					currentIndex_tregua = (currentIndex_tregua + 1) % iframe_tregua.length;
					document.getElementById('modalImageContainer').innerHTML = iframe_tregua[currentIndex_tregua];
				}
			});

		} else if (links_tregua[currentIndex_tregua]) {

			window.open(links_tregua[currentIframeIndex], '_blank');
		}


	});
});


const destinos_fundamento_tregua_ing = findAll('.js-destino-carrosel-tregua-ing');
let currentIndex_tregua_ing = 0;
let iframe_tregua_ing = [];
let links_tregua_ing = [];

destinos_fundamento_tregua_ing.forEach((item, index) => {
	item.addEventListener('click', () => {

		console.log("clicou aqui")

		iframe_tregua_ing = Array.from(destinos_fundamento_tregua_ing).map(item => item.querySelector('iframe') ? item.querySelector('iframe').outerHTML : null);
		links_tregua_ing = Array.from(destinos_fundamento_tregua_ing).map(item => item.querySelector('a') ? item.querySelector('a').href : null);
		currentIndex_tregua_ing = index;

		if (iframe_tregua_ing[currentIndex_tregua_ing]) {

			const modal_conteudo = document.getElementById("myModal_fundamento_carrosel");
			const bodyElement = document.querySelector('body');
			const htmlElement = document.documentElement;

			bodyElement.classList.add('no-scroll');


			modal_conteudo.style.display = "block";
			modal_conteudo.scrollTop = 0;

			const conteudoModal = `
                    <div class="relative inset-0 overflow-hidden flex h-screen items-center justify-center">
                        <div class="min-w-[1000px] w-full max-h-[300px] overflow-y-hidden">
                            <div class="relative">
                                <button id="close_fundamento_home" class="absolute right-0 top-1/4 text-white transform -translate-y-1/4 z-10">
                                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="white" stroke-opacity="0.2"/>
                                        <path d="M21.75 21.75L34.25 34.25" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M21.75 34.25L34.25 21.75" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="px-56 md:px-0 w-screen max-h-[800px] overflow-hidden h-screen flex items-center  py-24 z-0 ${iframe_tregua_ing.length === 1 ? 'justify-center' : 'justify-between'}" >
                                 ${iframe_tregua_ing.length > 1 ? `
                                <button id="prevImage" class="text-white transform bg-gray-200 p-2 z-10">
                                    <svg class="rotate-180" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                                    </svg>
                                </button>
                                ` : ''}
                                <div id="modalImageContainer" class="flex flex-col items-center">
                                    ${iframe_tregua_ing[currentIndex_tregua_ing]}
                                </div>
                                ${iframe_tregua_ing.length > 1 ? `
                                <button id="nextImage" class="text-white transform bg-gray-200 p-2 z-10">
                                    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                                    </svg>
                                </button>
                                ` : ''}
                            </div>
                        </div>
                    </div>
                `;

			const modal = document.getElementById("myModal_fundamento_carrosel");
			modal.querySelector(".modal-content_carrosel").innerHTML = conteudoModal;

			modalCases_fundament();

			document.getElementById('prevImage').addEventListener('click', () => {
				currentIndex_tregua_ing = (currentIndex_tregua_ing - 1 + iframe_tregua_ing.length) % iframe_tregua_ing.length;
				document.getElementById('modalImageContainer').innerHTML = iframe_tregua_ing[currentIndex_tregua_ing];
			});

			document.getElementById('nextImage').addEventListener('click', () => {
				currentIndex_tregua_ing = (currentIndex_tregua_ing + 1) % iframe_tregua_ing.length;
				document.getElementById('modalImageContainer').innerHTML = iframe_tregua_ing[currentIndex_tregua_ing];
			});

			// Adicionando eventos de teclado
			document.addEventListener('keydown', (event) => {
				if (event.key === 'ArrowLeft') {
					currentIndex_tregua_ing = (currentIndex_tregua_ing - 1 + iframe_tregua_ing.length) % iframe_tregua_ing.length;
					document.getElementById('modalImageContainer').innerHTML = iframe_tregua_ing[currentIndex_tregua_ing];
				} else if (event.key === 'ArrowRight') {
					currentIndex_tregua_ing = (currentIndex_tregua_ing + 1) % iframe_tregua_ing.length;
					document.getElementById('modalImageContainer').innerHTML = iframe_tregua_ing[currentIndex_tregua_ing];
				}
			});

		} else if (links_tregua_ing[currentIndex_tregua_ing]) {

			window.open(links_tregua_ing[currentIframeIndex], '_blank');
		}


	});
});






const destinos_blog = findAll('.js-destino-blog');
let currentIndex_blog = 0;
let iframe_blog = [];


destinos_blog.forEach((item, index) => {
	item.addEventListener('click', () => {

		console.log("clicou aqui")

		iframe_blog = Array.from(destinos_blog).map(item => item.querySelector('iframe') ? item.querySelector('iframe').outerHTML : null);

		currentIndex_blog = index;



		const modal_conteudo = document.getElementById("myModal_fundamento_carrosel");
		const bodyElement = document.querySelector('body');
		const htmlElement = document.documentElement;

		bodyElement.classList.add('no-scroll');


		modal_conteudo.style.display = "block";
		modal_conteudo.scrollTop = 0;

		const conteudoModal = `
                    <div class="relative inset-0 overflow-hidden flex h-screen items-center justify-center">
                        <div class="min-w-[1000px] w-full max-h-[300px] overflow-y-hidden">
                            <div class="relative">
                                <button id="close_fundamento_home" class="absolute right-0 top-1/4 text-white transform -translate-y-1/4 z-10">
                                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="white" stroke-opacity="0.2"/>
                                        <path d="M21.75 21.75L34.25 34.25" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M21.75 34.25L34.25 21.75" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="px-56 md:px-0 w-screen max-h-[800px] overflow-hidden h-screen flex items-center  py-24 z-0 ${iframe_blog.length === 1 ? 'justify-center' : 'justify-between'}" >
                                 ${iframe_blog.length > 1 ? `
                                <button id="prevImage" class="text-white transform bg-gray-200 p-2 z-10">
                                    <svg class="rotate-180" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                                    </svg>
                                </button>
                                ` : ''}
                                <div id="modalImageContainer" class="flex flex-col items-center">
                                    ${iframe_blog[currentIndex_blog]}
                                </div>
                                ${iframe_blog.length > 1 ? `
                                <button id="nextImage" class="text-white transform bg-gray-200 p-2 z-10">
                                    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                                    </svg>
                                </button>
                                ` : ''}
                            </div>
                        </div>
                    </div>
                `;

		const modal = document.getElementById("myModal_fundamento_carrosel");
		modal.querySelector(".modal-content_carrosel").innerHTML = conteudoModal;

		modalCases_fundament();

		document.getElementById('prevImage').addEventListener('click', () => {
			currentIndex_blog = (currentIndex_blog - 1 + iframe_blog.length) % iframe_blog.length;
			document.getElementById('modalImageContainer').innerHTML = iframe_blog[currentIndex_blog];
		});

		document.getElementById('nextImage').addEventListener('click', () => {
			currentIndex_blog = (currentIndex_blog + 1) % iframe_blog.length;
			document.getElementById('modalImageContainer').innerHTML = iframe_blog[currentIndex_blog];
		});

		// Adicionando eventos de teclado
		document.addEventListener('keydown', (event) => {
			if (event.key === 'ArrowLeft') {
				currentIndex_blog = (currentIndex_blog - 1 + iframe_blog.length) % iframe_blog.length;
				document.getElementById('modalImageContainer').innerHTML = iframe_blog[currentIndex_blog];
			} else if (event.key === 'ArrowRight') {
				currentIndex_blog = (currentIndex_blog + 1) % iframe_blog.length;
				document.getElementById('modalImageContainer').innerHTML = iframe_blog[currentIndex_blog];
			}
		});




	});
});


const destinos_blog_ing = findAll('.js-destino-blog-ing');
let currentIndex_blog_ing = 0;
let iframe_blog_ing = [];


destinos_blog_ing.forEach((item, index) => {
	item.addEventListener('click', () => {

		console.log("clicou aqui")

		iframe_blog_ing = Array.from(destinos_blog_ing).map(item => item.querySelector('iframe') ? item.querySelector('iframe').outerHTML : null);

		currentIndex_blog_ing = index;



		const modal_conteudo = document.getElementById("myModal_fundamento_carrosel");
		const bodyElement = document.querySelector('body');
		const htmlElement = document.documentElement;

		bodyElement.classList.add('no-scroll');


		modal_conteudo.style.display = "block";
		modal_conteudo.scrollTop = 0;

		const conteudoModal = `
                    <div class="relative inset-0 overflow-hidden flex h-screen items-center justify-center">
                        <div class="min-w-[1000px] w-full max-h-[300px] overflow-y-hidden">
                            <div class="relative">
                                <button id="close_fundamento_home" class="absolute right-0 top-1/4 text-white transform -translate-y-1/4 z-10">
                                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="white" stroke-opacity="0.2"/>
                                        <path d="M21.75 21.75L34.25 34.25" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M21.75 34.25L34.25 21.75" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="px-56 md:px-0 w-screen max-h-[800px] overflow-hidden h-screen flex items-center  py-24 z-0 ${iframe_blog_ing.length === 1 ? 'justify-center' : 'justify-between'}" >
                                 ${iframe_blog_ing.length > 1 ? `
                                <button id="prevImage" class="text-white transform bg-gray-200 p-2 z-10">
                                    <svg class="rotate-180" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                                    </svg>
                                </button>
                                ` : ''}
                                <div id="modalImageContainer" class="flex flex-col items-center">
                                    ${iframe_blog_ing[currentIndex_blog_ing]}
                                </div>
                                ${iframe_blog_ing.length > 1 ? `
                                <button id="nextImage" class="text-white transform bg-gray-200 p-2 z-10">
                                    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.03312e-06L12 10L2 20L0.225001 18.225L8.45 10L0.225002 1.775L2 1.03312e-06Z" fill="white"/>
                                    </svg>
                                </button>
                                ` : ''}
                            </div>
                        </div>
                    </div>
                `;

		const modal = document.getElementById("myModal_fundamento_carrosel");
		modal.querySelector(".modal-content_carrosel").innerHTML = conteudoModal;

		modalCases_fundament();

		document.getElementById('prevImage').addEventListener('click', () => {
			currentIndex_blog_ing = (currentIndex_blog_ing - 1 + iframe_blog_ing.length) % iframe_blog_ing.length;
			document.getElementById('modalImageContainer').innerHTML = iframe_blog_ing[currentIndex_blog_ing];
		});

		document.getElementById('nextImage').addEventListener('click', () => {
			currentIndex_blog_ing = (currentIndex_blog_ing + 1) % iframe_blog_ing.length;
			document.getElementById('modalImageContainer').innerHTML = iframe_blog_ing[currentIndex_blog_ing];
		});

		// Adicionando eventos de teclado
		document.addEventListener('keydown', (event) => {
			if (event.key === 'ArrowLeft') {
				currentIndex_blog_ing = (currentIndex_blog_ing - 1 + iframe_blog_ing.length) % iframe_blog_ing.length;
				document.getElementById('modalImageContainer').innerHTML = iframe_blog_ing[currentIndex_blog_ing];
			} else if (event.key === 'ArrowRight') {
				currentIndex_blog_ing = (currentIndex_blog_ing + 1) % iframe_blog_ing.length;
				document.getElementById('modalImageContainer').innerHTML = iframe_blog_ing[currentIndex_blog_ing];
			}
		});




	});
});



























mottaEquipeText()
fetchModal()
scrollToNextSection()



animacaoGsapManual()













